import React from 'react'

import Layout from '../components/layout'
import Index from '../components/index'

export default function Home () {
  return (
    <Layout>
      <Index />
    </Layout>
  )
}
